.featured-media { 
  overflow: hidden;

  .section__header {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
    
    @include media(370px) {
      max-width: 100%;
      margin-bottom: 5rem;
    }
  }

  &__controls {
    margin-top: 5rem;
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }

  &__btn {
    display: flex;
    align-items: center;
  }

  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    height: auto;

    &:not(.swiper-slide-visible) {
      opacity: 0;
      visibility: hidden;
    }

    .media-card {
      height: 100%;
    }
  }

  .swiper-pagination-bullets {
    .swiper-pagination-bullet {
      width: 1rem;
      height: 1rem;
      background-color: $air-blue;
      border: 1px solid rgba(96, 91, 255, 0.2);
      margin: 0;
      opacity: 1;

      @include media($md) {
        margin: 0 .7rem;
      }

      &::after {
        display: none;
      }

      &-active {
        background-color: $primary-color;
      }
    }
  }
}